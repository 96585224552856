<template>
    <div class="message-box">
        <div class="message-left">
            <el-tree
                    ref="tree"
                    :highlight-current="true"
                    :data="classList"
                    :indent="5"
                    @node-click="nodeClick"
                    :default-expanded-keys="[selectId]"
                    accordion
                    v-loading="classListLoading"
                    :props="defaultProps"
                    node-key="id">
                  <div slot-scope="{ node,data }" style="padding:10px 0;display: flex;align-items: center;justify-content: space-between;width: 100%">
                      <template v-if="data.children !== undefined">
                          <el-badge :value="getClassUnreadCountNumber(data)" :hidden="getClassUnreadCountNumber(data)===0?true:false" :max="99" class="item"> <div style="padding-right: 10px">{{ node.label }}</div></el-badge>
                          <div >（{{data.on_line_num}}/{{data.number}}）</div>
                      </template>
                      <template v-else>
                          <el-badge  :value="data.unread_count" :hidden="data.unread_count===0?true:false" :max="99" class="item">
                          <div style="display: flex;align-items: center;">
                              <div><el-avatar :size="20" :src="data.avatar_url"></el-avatar></div>
                              <div style="margin-left: 5px;padding-right: 10px">{{data.name}}</div>
                          </div>
                          </el-badge>
                          <div style="padding-right: 10px">
                              <i :style="{'background':data.isOnLine == 1 ? '#67C23A' : '#ccc'}" style="width: 6px;height: 6px;display: inline-block;border-radius: 50%"></i>
                          </div>
                      </template>
                  </div>
            </el-tree>
        </div>
        <div class="message-right"  >
            <div class="chat-box" v-if="curChatUser.id != undefined">
                <div style="width: 100%;margin-right: 10px">
                    <div class="m-user-name">
                        <div>{{curChatUser.name}}</div>
                    </div>
                    <div class="m-massageList" id="massageList" v-loading="loadingUserChat">
                        <div class="padding" v-if="userChatMsgList[curKey] === undefined" style="text-align: center;color: #C0C4CC">暂无聊天记录</div>
<!--                        <div class="m-msg-item m-msg-date">2021-10-10</div>-->
                        <div v-for="item in userChatMsgList[curKey]" :key="item.id"
                             :class="item.user_id == userId ?  'm-msg-me' : 'm-msg-other' "
                             class="m-msg-item"
                             :id="'l'+item.id"
                        >
                            <el-avatar class="m-msg-item-avatar" :size="35" v-if="item.user_id == userId?false:true"
                                       :src=" item.user_name_avatar_url" />
                            <div class="m-msg-item-info">
                                <div class="m-msg-name">{{item.user_name}}</div>


                                <div  class="m-msg-content" v-if="item.type === 1">
                                    <div style="overflow: hidden;word-wrap:break-word">{{item.content}}</div>
                                </div>
                                <el-image :preview-src-list="[imageUrl + item.content]" fit="cover" style="width: 160px;height: 160px" :src="imageUrl + item.content" class="m-msg-content" v-if="item.type === 2"></el-image>
                                <div class="m-msg-time">{{item.created_at.substring(16,10)}}</div>
                            </div>
                            <el-avatar class="m-msg-item-avatar" :size="35" v-if="item.user_id == userId "
                                       :src=" item.user_name_avatar_url " />
                        </div>
                    </div>
                    <div class="m-tool">
                    <div @click="sendPic"><i title="发送图片" style="cursor: pointer" class="el-icon-picture-outline"></i></div>
                    </div>
                    <div>
                        <el-input v-model="message"
                                class="m-input" :rows="8" resize="none"
                                  placeholder="输入聊天..." size="mini"
                                  type="textarea"/>
                    </div>
                    <div><el-button type="primary" :disabled="message === ''" size="mini" @click="sendMsgToUser">发送</el-button></div>
                </div>
                <div class="user-info" >
                    <div style="display:flex;justify-content: center">
                        <div style="padding: 10px">
                            <el-avatar :size="60" :src="userInfo.avatar_url" />

                            <div  style="margin-top: 10px;text-align: center">{{userInfo.name}}</div>


                        </div>
                    </div>
                  <div  style="margin-top: 10px;text-align: center"> <el-link @click="examine" type="primary">查看学员档案</el-link></div>
                    <div style="max-height: 600px;overflow: auto">
                        <div class="m-user-item">
                            <el-table
                                    v-if="userInfo.course !== undefined && userInfo.course.length > 0"
                                    border
                                    stripe
                                    size="mini"
                                    :data="userInfo.course"
                                    style="width: 98%">
                                <el-table-column
                                        prop="name"
                                        label="已购课程">
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="m-user-item">
                            <el-table
                                    v-if="userInfo.check_course !==undefined && userInfo.check_course.length > 0"
                                    border
                                    stripe
                                    size="mini"
                                    :data="userInfo.check_course"
                                    style="width: 98%">
                                <el-table-column
                                        prop="name"
                                        label="打卡课程">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>


                </div>
            </div>

        </div>
        <cover ref="importFile" style="display: none" uploadPath="classChat" @success="uploadSuccess"></cover>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import Cover from "../../../components/cover";

    export default {
        name: "index",
        components: {Cover},
        data(){
            return {
                imageUrl:config.imageUrl,
                classListLoading:false,
                type:1,
                message:'',
                loadingUserChat:false,
                userId:0,
                data: [],
                curChatUser: {},
                selectId:0,
                curChatRoomId:'',
                curKey:'',
                // messageList: [],
                defaultProps: {
                    label: 'name',
                    id: 'id',
                    isLeaf:'isLeaf'
                }
            }
        },
        computed:{
            ...mapState('classChat',['classList','userChatMsgList','userInfo']),
            // ...mapGetters('classChat',['getClassUnreadCountNumber'])
        },
        watch:{
            '$store.state.socket.msg'(newMsg){
                if(newMsg.msg_type === 'chat'){
                    var key = newMsg.data.class_id+'_'+newMsg.data.user_id+'_'+newMsg.data.chat_room_id
                    this.getClassUserInfo({userId:newMsg.data.user_id})
                    if(this.userChatMsgList[key] !== undefined){
                        this.addUserChatMsg({key:key,data:[newMsg.data]})
                        this.$forceUpdate()
                        if(this.curKey === key){
                            setTimeout(()=>{
                                this.toEndChat()
                            },50)
                        }
                    }
                }
            },
        },
        methods:{
            ...mapActions('classChat',['initClassChatList',
                'getUserChatRecords',
                'clearUnreadMessage',
                'sendMessage','getClassUserInfo',
                'setUserChatRoom','setUserChatMsgList','addUserChatMsg']),
          examine(){
            this.$router.push('/studentNameCard')
            localStorage.setItem('studentNameCardID',this.curChatUser.user_id)
          },
            getClassUnreadCountNumber(data){
                let unreadCount = 0
                data.children.forEach(item => {
                    unreadCount = unreadCount + item.unread_count
                })
                return unreadCount
            },
            uploadSuccess(path){
                this.type = 2
                this.message = path
                this.sendMsgToUser()
            },
            sendPic(){
                this.$refs.importFile.$el.getElementsByClassName('el-upload el-upload--picture-card')[0].click()
            },
            async sendMsgToUser(){
                let message = await this.sendMessage({
                    type:this.type,
                    chatRoomId:this.curChatUser.chat_room_id,
                    toUserId:this.curChatUser.user_id,
                    classId:this.curChatUser.class_id,
                    content:this.message
                })
                this.addUserChatMsg({key:this.curKey,data:[message.data]})
                setTimeout(()=>{
                    this.toEndChat()
                },50)
                let data = this.curChatUser
                data.chat_room_id = message.data.chat_room_id
                this.curChatUser = data
                this.setUserChatRoom({
                    userId:this.userId,
                    classId:this.curChatUser.class_id,
                    chatRoomId:message.data.chat_room_id,
                    unreadCount:0
                })
                this.message = ''
                this.type = 1
            },
            async nodeClick(e){

                console.log( e.id !== this.curChatUser.id)
                if(e.children === undefined && e.id !== this.curChatUser.id){
                    this.loadingUserChat = true
                    this.getClassUserInfo({userId:e.user_id})
                    // console.log(e)
                    this.curChatUser = e
                    this.curKey = e.class_id+'_'+e.user_id+'_'+e.chat_room_id
                    if(this.userChatMsgList[this.curKey] === undefined){
                        this.clearUnreadMessage({classId:e.class_id,userId:e.user_id, chatRoomId:e.chat_room_id,unreadCount:0})
                      let classId;
                      for (let i = 0; i < this.classList.length; i++) {
                        let value = this.classList[i].children
                        if(value){
                          let index =  value.findIndex(v=>v.id==e.id)
                         if(index !== -1){
                           classId = this.classList[i].id
                         }
                        }
                      }
                        let res = await this.getUserChatRecords({userId:e.user_id,chatRoomId:this.curChatUser.chat_room_id,classId})
                        this.setUserChatMsgList({key:this.curKey,data:res.data})
                    }
                    this.loadingUserChat = false
                    setTimeout(()=>{
                        this.toEndChat()
                    },50)
                }
            },
            toEndChat(){
                if(this.userChatMsgList[this.curKey] !== undefined && this.userChatMsgList[this.curKey].length>3){
                    this.$nextTick(() =>{
                        let s = document.getElementById('massageList')
                        let a = document.getElementById('l'+this.userChatMsgList[this.curKey][this.userChatMsgList[this.curKey].length-1].id)
                        s.scrollTo(0,a.offsetTop)
                    })
                }
            }
        },
        mounted() {
            this.$store.dispatch('classChat/initClassChatList')
            this.userId = this.$cookies.get('userId')
            // this.selectId = this.$route.query.classId

            this.$nextTick(() => {
                setTimeout(() => {
                    this.selectId = this.$route.query.classId
                    this.curChatRoomId = this.$route.query.chatRoomId
                },1500)
                this.$refs['tree'].setCurrentKey(this.selectId)
            })

            if(this.$route.query.chatRoomId !== undefined)
            {
                this.nodeClick({
                    name:this.$route.query.user,
                    id:this.$route.query.userId,
                    class_id:this.$route.query.classId,
                    user_id:this.$route.query.userId,
                    chat_room_id:this.$route.query.chatRoomId,
                })
            }

        }
    }
</script>

<style>
    .message-box{
        width: 100%;
        display: flex;
        background: rgb(244, 245, 249);
        height: 100%;
    }
    .message-left{
        width: 300px;
        background: #fff;
        border-radius: 5px;
        padding:10px;
        height: 100%;
    }
    .message-right{
        width: calc(100% - 310px);
        background: #fff;
        height: 100%;
        margin-left: 10px;
        border-radius: 5px;
        padding:10px;
    }
    .chat-box{
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
    .user-info{
        width: 300px;
        height: 100%;
        border-left: 1px solid #f1f1f1;
        padding-left:10px
    }
    .el-tree-node__content{
        height: auto;
    }
    .m-user-name{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 100%;
        border-bottom: 1px solid #f1f1f1;
    }
    .m-massageList{
        padding: 10px;
        height: 500px;
        overflow: auto;
    }
    .m-tool{
        border-top: 1px solid #f1f1f1;
        padding: 10px;
    }
    .m-input .el-textarea__inner{
        border:0;
        padding:0;
    }
    .m-msg-item{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .m-msg-date{
        font-size: 12px;
        width: 120px;
        background: #ddd;
        color: #fff;
        text-align: center;
        justify-content: center;
        padding: 5px;
        border-radius: 5px;
        margin: 20px auto;
    }
    .m-msg-item-info{
        margin-left: 10px;
        max-width: calc(100% - 45px);
    }
    .m-user-item{
        margin-top: 10px;
        color: #909399;
    }
    .m-msg-name{
        font-size: 12px;
        color: #C0C4CC;
    }
    .m-msg-content{
        font-size: 12px;
        margin: 5px 0;
        padding:10px;
        color: #000;
        background: #eee;
        border-radius: 5px;
        position: relative;
        line-height: 20px;
    }
    .m-msg-content::before{
        position: absolute;
        width: 8px;
        height: 8px;
        content: " ";
        background: #eee;
        left: -4px;
        transform: rotate(45deg);
        top: 14px;
    }
    .m-msg-item-avatar{
        margin-top: 10px;
        align-self: flex-start;
    }
    .m-msg-me{
        justify-content: end;
    }
    .m-msg-me .m-msg-item-avatar{
        margin-left: 10px;
    }
    .m-msg-me .m-msg-name{
        text-align: right;
    }
    .m-msg-me .m-msg-item-info{
        margin-left: 0;
    }
    .m-msg-me .m-msg-item-info .m-msg-content{
        background: #82da57;
    }
    .m-msg-me .m-msg-item-info .m-msg-content::before{
        right: -4px;
        left: auto;
        background: #82da57;
    }
    .m-msg-time{
        font-size: 12px;
        color: #ccc;
    }
</style>